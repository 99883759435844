import React from 'react';
import classNames from 'classnames';
import { debounce } from 'lodash';
import { useI18n } from '@paprika/l10n';
import tokens from '@paprika/tokens';
import Table, { TH } from '@paprika/table';
import Input from '@paprika/input';
import Select from '@paprika/select';
import Confirmation from '@paprika/confirmation';
import Popover from '@paprika/popover';
import Switch from '@paprika/switch';
import Textarea from '@paprika/textarea';
import Radio from '@paprika/radio';
import Button from '@paprika/button';
import FormElement, { Fieldset } from '@paprika/form-element';
import Pill from '@paprika/pill';
import ArrowUpIcon from '@acl-services/wasabicons/lib/ArrowUp';
import RemoveIcon from '@acl-services/wasabicons/lib/Trashbin';
import EditAlt from '@acl-services/wasabicons/lib/EditAlt';
import User from 'types/User';
import Variable from 'types/Variable';
import VariableErrors from 'types/VariableErrors';
import VariableType from 'enums/VariableType';
import VariableProperty from 'enums/VariableProperty';
import InfoTooltip from 'components/InfoTooltip/InfoTooltip';
import CopyButton from './CopyButton';
import useVariablesFocus from './useVariablesFocus';
import VariableInputWithError from './VariableInputWithError';

export const APPLY_CHANGE_DELAY = 200;
export const DEBOUNCE_DELAY = 500;

type Props = {
  duplicateErrors: VariableErrors;
  isSystemUserEnabled?: boolean;
  onRemoveVariable: (variableId: string) => void;
  onVariableChange: (variable: Variable, property: VariableProperty, value) => void;
  onToggleReadOnlyView: (variableID: string) => void;
  singleErrors: VariableErrors;
  systemUsers: User[];
  variables: Variable[];
};

type VariableRowType = {
  row: Variable;
  rowIndex: number;
};

export default function VariablesTable({
  duplicateErrors,
  isSystemUserEnabled = false,
  onRemoveVariable,
  onVariableChange,
  onToggleReadOnlyView,
  singleErrors,
  systemUsers,
  variables,
}: Props) {
  const I18n = useI18n();
  const { getInputRef, handleFocusOnRemoveVariable } = useVariablesFocus(variables);

  function isSystemUserVariable(variable: Variable) {
    return variable.type === VariableType.SystemUser;
  }

  function getSelectableVariableTypes() {
    if (isSystemUserEnabled) {
      return Object.values(VariableType);
    }
    return Object.values(VariableType).filter((variableType) => variableType !== VariableType.SystemUser);
  }

  function handleConfirmRemove(variableId, rowIndex) {
    handleFocusOnRemoveVariable(rowIndex);
    onRemoveVariable(variableId);
  }

  function isVariableCollapsible(variable: Variable) {
    return (typeof variable.isReadOnly === 'undefined' || variable.isReadOnly === true) && !isVariableErrored(variable);
  }

  function variableTypeDisplayValue(type: VariableType) {
    return I18n.t(`variables.types.${type}`);
  }

  function variableNameErrors(variable: Variable) {
    return duplicateErrors[variable.id]?.name || singleErrors[variable.id]?.name || null;
  }

  function variableTaskInputErrors(variable: Variable) {
    return duplicateErrors[variable.id]?.taskInputLabel || singleErrors[variable.id]?.taskInputLabel || null;
  }

  function isVariableErrored(variable: Variable) {
    return Boolean(
      variableNameErrors(variable) ||
        variableTaskInputErrors(variable) ||
        (isSystemUserVariable(variable) && !isSystemUserValueValid(variable.value)),
    );
  }

  function renderNameInput({ row: variable, rowIndex: variableIndex }: VariableRowType) {
    return isVariableCollapsible(variable) ? (
      <div className="variable__input-container">
        <span className="variable__copy-input-text-read-only">{variable.name}</span>
        <CopyButton value={variable.name} buttonKind="minor" />
      </div>
    ) : (
      <FormElement>
        <FormElement.Label className="variable__input-label">
          <span>{I18n.t('variables.input_labels.name')} </span>
          <span className="variable__input-label-normal">{I18n.t('variables.input_labels.name_example')}</span>
        </FormElement.Label>
        <FormElement.Content className="variable__input-content">
          {(a11yProps) => (
            <VariableInputWithError
              {...a11yProps}
              aria-required
              isCopyInput
              dataTestId="variable-name-input"
              error={variableNameErrors(variable)}
              inputValue={variable.name}
              onChange={(value) => onVariableChange(variable, VariableProperty.Name, value)}
              ref={getInputRef(variableIndex)}
              variableId={variable.id}
            />
          )}
        </FormElement.Content>
      </FormElement>
    );
  }

  function renderReadOnlyValueInput(variable: Variable) {
    if (!isSystemUserVariable(variable)) {
      return variable.type === VariableType.Password ? (
        <Input disabled className="variable__input-read-only" defaultValue={variable.value} type={'password'} />
      ) : (
        variable.value
      );
    }

    if (!isSystemUserValueValid(variable.value)) return '';

    const systemUser = systemUsers.find((systemUser) => systemUser.id === variable.value);
    return systemUser ? `${systemUser.firstName} ${systemUser.lastName}` : '';
  }

  function renderValueInput({ row: variable }: VariableRowType) {
    const handleChange = debounce((value) => {
      onVariableChange(variable, VariableProperty.Value, value);
    }, DEBOUNCE_DELAY);

    return isVariableCollapsible(variable) ? (
      renderReadOnlyValueInput(variable)
    ) : (
      <FormElement>
        <FormElement.Label className="variable__input-label">
          {I18n.t('variables.input_labels.value')}
        </FormElement.Label>
        {isSystemUserVariable(variable) ? (
          renderSystemUsersSelect(variable)
        ) : (
          <FormElement.Content className="variable__input-content">
            {(a11yProps) => (
              <Input
                {...a11yProps}
                data-testid="variable-flow-value-input"
                defaultValue={variable.value}
                key={`${variable.id}_${variable.type}`}
                onChange={(event) => {
                  const value = event ? event.target.value : '';
                  handleChange(value);
                }}
                type={variable.type === VariableType.Password ? 'password' : 'text'}
                hasClearButton={variable.type === VariableType.Password}
              />
            )}
          </FormElement.Content>
        )}
      </FormElement>
    );
  }

  function renderSystemUsersSelect(variable: Variable) {
    return (
      <FormElement.Content className="variable__input-content">
        {(a11yProps) => (
          <>
            <Select
              {...a11yProps}
              a11yText={I18n.t('variables.column_headers.value')}
              data-testid="variable-system-users-select"
              key={variable.id}
              onChange={(event) => onVariableChange(variable, VariableProperty.Value, event.target.value)}
              value={isSystemUserValueValid(variable.value) ? variable.value : ''}
              placeholder={I18n.t('variables.system_users.placeholder')}
              hasError={!isSystemUserValueValid(variable.value)}
            >
              {systemUsers?.map((systemUser) => (
                <option key={systemUser.id} value={systemUser.id}>
                  {userDisplayName(systemUser)}
                </option>
              ))}
            </Select>
            {singleErrors[variable.id]?.value && (
              <FormElement.Error>{singleErrors[variable.id]?.value}</FormElement.Error>
            )}
          </>
        )}
      </FormElement.Content>
    );
  }

  function isSystemUserValueValid(value) {
    return !!systemUsers?.find((systemUser) => systemUser.id === value);
  }

  function userDisplayName(user: User) {
    return `${user.firstName} ${user.lastName}`;
  }

  function renderTypeListBox({ row: variable }: VariableRowType) {
    return isVariableCollapsible(variable) ? (
      <span className="variable__text-read-only">{variableTypeDisplayValue(variable.type)}</span>
    ) : (
      <FormElement>
        <FormElement.Label className="variable__input-label">{I18n.t('variables.input_labels.type')}</FormElement.Label>
        <FormElement.Content className="variable__input-content">
          {(a11yProps) => (
            <Select
              {...a11yProps}
              data-testid="variable-type-select"
              key={variable.id}
              onChange={(event) => onVariableChange(variable, VariableProperty.Type, event.target.value)}
              value={variable.type}
            >
              {getSelectableVariableTypes()?.map((type) => (
                <option key={type} value={type}>
                  {variableTypeDisplayValue(type)}
                </option>
              ))}
            </Select>
          )}
        </FormElement.Content>
      </FormElement>
    );
  }

  function renderReadOnlyPill(variable: Variable) {
    const pillColor = variable.isTaskInput ? Pill.types.color.GREEN : Pill.types.color.GREY;
    const pillSubtext =
      variable.isTaskInput &&
      (variable.isTaskInputValueOptional
        ? I18n.t('variables.task_input_optional')
        : I18n.t('variables.task_input_required'));
    return (
      <div className="variable__pill-container-read-only">
        <Pill pillColor={pillColor}>
          {variable.isTaskInput
            ? I18n.t('variables.task_input_switch_enabled')
            : I18n.t('variables.task_input_switch_disabled')}
        </Pill>
        {pillSubtext}
      </div>
    );
  }

  function renderTaskInputOptions({ row: variable }: VariableRowType) {
    if (isSystemUserVariable(variable)) return null;

    return isVariableCollapsible(variable) ? (
      renderReadOnlyPill(variable)
    ) : (
      <>
        <FormElement>
          <FormElement.Label className="variable__input-label">
            {I18n.t('variables.input_labels.task_input')}
          </FormElement.Label>
          <FormElement.Content className="variable__task-input-switch-content variable__input-content">
            {(a11yProps) => (
              <div className="variable__task-input-switch">
                <Switch
                  {...a11yProps}
                  a11yText={I18n.t('variables.column_headers.task_input')}
                  data-testid="variable-task-input-switch"
                  isChecked={variable.isTaskInput}
                  isDisabled={variable.type === VariableType.Password}
                  onChange={() => onVariableChange(variable, VariableProperty.IsTaskInput, !variable.isTaskInput)}
                />
                {I18n.t('variables.task_input_switch_enabled')}
              </div>
            )}
          </FormElement.Content>
        </FormElement>
        {variable.isTaskInput || variable.type === VariableType.Password ? (
          <Fieldset data-testid="variable-task-input-optional-radio" className="variable__optional-value-radio">
            <Fieldset.Label className="variable__label-radio">
              {I18n.t('variables.input_labels.task_input')}
            </Fieldset.Label>
            <Fieldset.Content>
              <Radio.Group
                onChange={(activeIndex) =>
                  onVariableChange(variable, VariableProperty.IsTaskInputValueOptional, activeIndex === 1)
                }
              >
                <Radio data-testid="variable-task-input-required" isChecked={!variable.isTaskInputValueOptional}>
                  {I18n.t('variables.task_radio_user_input_required')}
                </Radio>
                <Radio data-testid="variable-task-input-optional" isChecked={variable.isTaskInputValueOptional}>
                  {I18n.t('variables.task_radio_user_input_optional')}
                </Radio>
              </Radio.Group>
            </Fieldset.Content>
          </Fieldset>
        ) : null}
      </>
    );
  }

  function renderTaskDescription({ row: variable }: VariableRowType) {
    if (!variable.isTaskInput || isSystemUserVariable(variable) || isVariableCollapsible(variable)) return null;
    return (
      <FormElement className="variable__task-description">
        <FormElement.Label className="variable__input-label">
          <span>{I18n.t('variables.input_labels.task_input_description')} </span>
          <span className="variable__input-label-normal">
            {I18n.t('variables.input_labels.task_input_optional_label')}
          </span>
        </FormElement.Label>
        <FormElement.Content>
          {(a11yProps) => (
            <Textarea
              {...a11yProps}
              key={variable.id}
              data-testid="variable-task-input-description"
              onBlur={(event) => onVariableChange(variable, VariableProperty.TaskInputDescription, event.target.value)}
              defaultValue={variable.taskInputDescription}
            />
          )}
        </FormElement.Content>
      </FormElement>
    );
  }

  function renderTaskLabel({ row: variable }: VariableRowType) {
    if (!variable.isTaskInput || isSystemUserVariable(variable)) return null;
    return isVariableCollapsible(variable) ? (
      <span className="variable__text-read-only">{variable.taskInputLabel}</span>
    ) : (
      <FormElement>
        <FormElement.Label className="variable__input-label">
          {I18n.t('variables.input_labels.task_input_label')}
        </FormElement.Label>
        <FormElement.Content className="variable__input-content">
          {(a11yProps) => (
            <VariableInputWithError
              {...a11yProps}
              error={variableTaskInputErrors(variable)}
              aria-required
              dataTestId="variable-task-input-label-input"
              onChange={(value) => onVariableChange(variable, VariableProperty.TaskInputLabel, value)}
              inputValue={variable.taskInputLabel}
              variableId={variable.id}
            />
          )}
        </FormElement.Content>
      </FormElement>
    );
  }

  function renderVariableButtons({ row: variable, rowIndex: variableIndex }: VariableRowType) {
    const variableChangeViewToggleText =
      (!isVariableErrored(variable) &&
        (isVariableCollapsible(variable) ? I18n.t('variables.edit_view') : I18n.t('variables.read_only_view'))) ||
      I18n.t('variables.edit_view_error');

    const variableChangeViewToggleButton = (
      <Button.Icon
        a11yText={variableChangeViewToggleText}
        kind="minor"
        onClick={(event) => {
          onToggleReadOnlyView(variable.id);
          event.stopPropagation();
        }}
        isSemantic={false}
        isDisabled={isVariableErrored(variable)}
      >
        {isVariableCollapsible(variable) ? (
          <EditAlt color={tokens.textColor.icon} />
        ) : (
          <ArrowUpIcon color={tokens.textColor.icon} />
        )}
      </Button.Icon>
    );
    return (
      <>
        <Popover isDark isEager offset={8} shouldKeepFocus zIndex={9}>
          <Popover.Trigger tabIndex={-1}>{variableChangeViewToggleButton}</Popover.Trigger>
          <Popover.Content>
            <Popover.Card>{variableChangeViewToggleText}</Popover.Card>
          </Popover.Content>
          <Popover.Tip />
        </Popover>
        {!isVariableCollapsible(variable) && (
          <Confirmation
            key={variable.id}
            align="top"
            confirmLabel={I18n.t('variables.remove_confirmation.confirm_button')}
            heading={I18n.t('variables.remove_confirmation.heading', { name: variable.name || 'variable' })}
            onConfirm={() => handleConfirmRemove(variable.id, variableIndex)}
            edge="right"
            zIndex={9}
          >
            <Confirmation.TriggerButton
              className="variable__remove_button"
              kind="minor"
              buttonType="icon"
              a11yText={I18n.t('variables.remove_tip')}
              data-testid="variable-remove-button"
            >
              <Popover isDark isEager offset={8} shouldKeepFocus zIndex={9}>
                <Popover.Trigger tabIndex={-1}>
                  <RemoveIcon color={tokens.textColor.icon} />
                </Popover.Trigger>
                <Popover.Content>
                  <Popover.Card>{I18n.t('variables.remove_tip')}</Popover.Card>
                </Popover.Content>
                <Popover.Tip />
              </Popover>
            </Confirmation.TriggerButton>
          </Confirmation>
        )}
      </>
    );
  }

  function renderVariableDefinitionHeader() {
    const headerText = I18n.t('variables.column_headers.variable_definition');
    const typeTooltipContent = isSystemUserEnabled
      ? I18n.t('variables.column_headers.tooltips.type.has_system_user')
      : I18n.t('variables.column_headers.tooltips.type.no_system_user');
    const helpLink = I18n.t('help_link.highbond', { article: 'rob-hcl-variables' });

    return (
      <div className="variables__table_header" aria-label={headerText}>
        {headerText}
        <InfoTooltip
          a11yText={I18n.t('variables.column_headers.tooltips.read_more_variable_definition')}
          content={`
            ${I18n.t('variables.column_headers.tooltips.name')}
            ${typeTooltipContent}
            ${I18n.t('variables.column_headers.tooltips.value')}
            ${I18n.t('variables.column_headers.tooltips.usage', { helpLink })}
          `}
          maxWidth={500}
        />
      </div>
    );
  }

  function renderRobotTaskInputHeader() {
    const headerText = I18n.t('variables.column_headers.robot_task_input');
    return (
      <div className="variables__table_header" aria-label={headerText}>
        {headerText}
        <InfoTooltip
          a11yText={I18n.t('variables.column_headers.tooltips.read_more_task_input')}
          content={I18n.t('variables.column_headers.tooltips.task_input')}
        />
      </div>
    );
  }

  function getCellProps({ row: variable }) {
    const readOnlyClasses = classNames(
      { 'variables__column-overflow': isVariableCollapsible(variable) },
      { 'variables__column-read-only': isVariableCollapsible(variable) },
      { 'variables__column-error': isVariableErrored(variable) },
    );
    return {
      className: `${readOnlyClasses}`,
      onClick: () => {
        if (isVariableCollapsible(variable)) onToggleReadOnlyView(variable.id);
      },
    };
  }

  return (
    <Table
      className="variables__table"
      a11yText={I18n.t('variables.table_label')}
      data={variables}
      borderType={Table.types.border.HORIZONTAL}
    >
      <Table.Headers>
        <tr>
          <TH data-variable-cell="variable-header-column" scope="colgroup" width="58%" colSpan="3">
            {renderVariableDefinitionHeader()}
          </TH>
          <TH data-variable-cell="robot-input-header-column" scope="colgroup" width="40%" colSpan="2">
            {renderRobotTaskInputHeader()}
          </TH>
          <TH scope="colgroup" width="4%" />
        </tr>
      </Table.Headers>
      <Table.ColumnDefinition
        cellProps={getCellProps}
        data-variable-cell="name-column"
        header={`${I18n.t('variables.column_headers.name')}`}
        width="23.5%"
        cell={renderNameInput}
      />
      <Table.ColumnDefinition
        cellProps={getCellProps}
        data-variable-cell="type-column"
        header={`${I18n.t('variables.column_headers.type')}`}
        width="12.5%"
        cell={renderTypeListBox}
      />
      <Table.ColumnDefinition
        cellProps={getCellProps}
        data-variable-cell="value-column"
        header={`${I18n.t('variables.column_headers.value')}`}
        width="22%"
        cell={renderValueInput}
      />
      <Table.ColumnDefinition
        cellProps={getCellProps}
        data-variable-cell="input-options-column"
        header={`${I18n.t('variables.column_headers.task_input')}`}
        width="17%"
        cell={renderTaskInputOptions}
      />
      <Table.ColumnDefinition
        cellProps={getCellProps}
        data-variable-cell="input-column"
        header={I18n.t('variables.column_headers.input_label')}
        width="23%"
        cell={(variableRowType) => {
          return (
            <>
              {renderTaskLabel(variableRowType)}
              {renderTaskDescription(variableRowType)}
            </>
          );
        }}
      />
      <Table.ColumnDefinition cellProps={getCellProps} header="" width="4%" cell={renderVariableButtons} />
    </Table>
  );
}
