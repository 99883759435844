import React from 'react';
import '@paprika/helpers/lib/polyfills/elementScroll';
import isMac from 'utils/isMac';
import ScriptCell from 'types/ScriptCell';
import { scrollTo, cellClassName, cellEditorClassName } from './ScriptNodeHelpers';

const macShortcutKeys = {
  RUN: ['metaKey', 'shiftKey', 'enter'],
  STOP: ['metaKey', 'shiftKey', 'i'],
  PREV: [
    ['metaKey', 'shiftKey', 'arrowup'],
    ['metaKey', 'shiftKey', 'k'],
  ],
  NEXT: [
    ['metaKey', 'shiftKey', 'arrowdown'],
    ['metaKey', 'shiftKey', 'j'],
  ],
  FIRST: [
    ['metaKey', 'shiftKey', 'home'],
    ['metaKey', 'shiftKey', 'h'],
  ],
  LAST: [
    ['metaKey', 'shiftKey', 'end'],
    ['metaKey', 'shiftKey', 'l'],
  ],
  ADD_BELOW: ['metaKey', 'shiftKey', 'b'],
  ADD_ABOVE: ['metaKey', 'shiftKey', 'a'],
  DELETE: ['metaKey', 'shiftKey', 'd'],
};

const winShortcutKeys = {
  RUN: ['ctrlKey', 'shiftKey', 'enter'],
  STOP: ['ctrlKey', 'shiftKey', 'i'],
  PREV: [
    ['ctrlKey', 'shiftKey', 'arrowup'],
    ['ctrlKey', 'shiftKey', 'k'],
  ],
  NEXT: [
    ['ctrlKey', 'shiftKey', 'arrowdown'],
    ['ctrlKey', 'shiftKey', 'j'],
  ],
  FIRST: [
    ['ctrlKey', 'shiftKey', 'home'],
    ['ctrlKey', 'shiftKey', 'h'],
  ],
  LAST: [
    ['ctrlKey', 'shiftKey', 'end'],
    ['ctrlKey', 'shiftKey', 'l'],
  ],
  ADD_BELOW: ['ctrlKey', 'shiftKey', 'b'],
  ADD_ABOVE: ['ctrlKey', 'shiftKey', 'a'],
  DELETE: ['ctrlKey', 'shiftKey', 'd'],
};

export const shortcutKeys = isMac ? macShortcutKeys : winShortcutKeys;

const modifierKeys = ['shiftKey', 'ctrlKey', 'metaKey'];

export const modifierSymbols = {
  ctrlKey: '⌃',
  metaKey: '⌘',
  shiftKey: '⇧',
};

function getKey(keyCombo: string[]): string[] {
  return keyCombo.filter((key) => !modifierKeys.includes(key));
}

function getModifiers(keyCombo: string[]): string[] {
  return keyCombo.filter((key) => modifierKeys.includes(key));
}

function isValidCommand(keyCombo: string[], event: KeyboardEvent): boolean {
  const matchesKey = getKey(keyCombo).includes(event.key.toLowerCase());
  const matchesModifiers = getModifiers(keyCombo).filter((modifier) => !event[modifier]).length === 0;
  return matchesKey && matchesModifiers;
}

function getCommand(event: KeyboardEvent): string | undefined {
  const shortcutKeys = isMac ? macShortcutKeys : winShortcutKeys;
  return Object.keys(shortcutKeys).find((command) => {
    const keyCombo = shortcutKeys[command];
    const hasMultiCombos = typeof keyCombo[0] === 'string';
    return hasMultiCombos
      ? isValidCommand(keyCombo, event)
      : keyCombo.filter((_keyCombo) => isValidCommand(_keyCombo, event)).length === 1;
  });
}

interface Args {
  currentNodeCells: ScriptCell[];
  getCurrentCellIndex: () => number;
  isConnected: boolean;
  onSelectCell: (cellId: string) => () => void;
  onRunCurrentCell: () => void;
  onStop: () => void;
  onAddCellAbove: () => void;
  onAddCellBelow: () => void;
  onShowRemoveConfirmation: () => void;
}

export default function useShortcutKeys({
  currentNodeCells,
  getCurrentCellIndex,
  isConnected,
  onSelectCell,
  onRunCurrentCell,
  onStop,
  onAddCellAbove,
  onAddCellBelow,
  onShowRemoveConfirmation,
}: Args) {
  const selectCell = React.useCallback(
    (index): void => {
      const prevFocusOnEditor = document.activeElement?.className.indexOf(cellEditorClassName) !== -1;
      const cellId = currentNodeCells[index].id;
      onSelectCell(cellId)();
      const $cell = document.getElementsByClassName(cellClassName)[index] as HTMLElement;
      const $focusTarget = prevFocusOnEditor
        ? ($cell.getElementsByClassName(cellEditorClassName)[0] as HTMLElement)
        : $cell;
      $focusTarget?.focus();
      scrollTo($cell?.offsetTop);
    },
    [currentNodeCells, onSelectCell],
  );

  const shortcutFunctions = React.useMemo(
    () => ({
      RUN: () => {
        if (isConnected) {
          onRunCurrentCell();
        }
      },
      STOP: () => {
        if (isConnected) {
          onStop();
        }
      },
      PREV: () => {
        const currentCellIndex = getCurrentCellIndex();
        if (currentCellIndex > 0) {
          selectCell(currentCellIndex - 1);
        }
      },
      NEXT: () => {
        const currentCellIndex = getCurrentCellIndex();
        if (currentCellIndex + 1 < currentNodeCells.length) {
          selectCell(currentCellIndex + 1);
        }
      },
      FIRST: () => {
        const currentCellIndex = getCurrentCellIndex();
        if (currentCellIndex !== 0) {
          selectCell(0);
        }
      },
      LAST: () => {
        const currentCellIndex = getCurrentCellIndex();
        if (currentCellIndex !== currentNodeCells.length - 1) {
          selectCell(currentNodeCells.length - 1);
        }
      },
      ADD_ABOVE: onAddCellAbove,
      ADD_BELOW: onAddCellBelow,
      DELETE: onShowRemoveConfirmation,
    }),
    [
      currentNodeCells,
      getCurrentCellIndex,
      isConnected,
      selectCell,
      onRunCurrentCell,
      onStop,
      onAddCellAbove,
      onAddCellBelow,
      onShowRemoveConfirmation,
    ],
  );

  const handleKeyDown = React.useCallback(
    (event: KeyboardEvent) => {
      const command = getCommand(event);
      if (command) {
        event.preventDefault();
        shortcutFunctions[command]();
      }
    },
    [shortcutFunctions],
  );

  React.useLayoutEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);
}
