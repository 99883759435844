import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import Button from '@paprika/button';
import Heading from '@paprika/heading';
import stylers from '@paprika/stylers';
import tokens from '@paprika/tokens';
import { useI18n } from '@paprika/l10n';
import ScriptIcon from '@acl-services/wasabicons/lib/Script';
import VariablesIcon from '@acl-services/wasabicons/lib/Variable';
import FilesIcon from '@acl-services/wasabicons/lib/FileText';

import { RootState } from 'rootReducer';
import { addScriptCell, removeScriptCell, startScriptCellExecution, updateScriptCell } from 'slices/ScriptCellsSlice';
import FlowNode from 'types/FlowNode';
import ScriptCell from 'types/ScriptCell';
import flippers from 'utils/flippers';
import { useWebSocket } from 'providers/WebSocketProvider';
import UnsavedChangesModal from 'components/modals/UnsavedChangesModal';
import ScriptToast from 'components/ScriptToast/ScriptToast';

import useShortcutKeys from './useShortcutKeys';
import ScriptNodeToolbar from './ScriptNodeToolbar';
import ScriptNodeCell from './ScriptNodeCell';
import FilesPanel from './FilesPanel';
import RemoveCellConfirmation from './RemoveCellConfirmation';
import { scrollTo, cellClassName, cellEditorClassName, cellContainerClassName } from './ScriptNodeHelpers';

import 'components/NodeDetails/NodeDetails.scss';
import './ScriptNodeDetails.scss';

type Props = {
  commitCount: number;
  nodeId: string;
  onClose: () => void;
  onCommitButtonClick: () => void;
  onVariablesButtonClick: () => void;
};

const iconSize = '17px';

export default function ScriptNodeDetails({
  commitCount,
  nodeId,
  onClose,
  onCommitButtonClick,
  onVariablesButtonClick,
}: Props) {
  const I18n = useI18n();
  const dispatch = useDispatch();
  const webSocket = useWebSocket();

  const flowNodes: FlowNode[] = useSelector((state: RootState) => state.flowNodes.present);
  const currentNode = flowNodes.find((node) => node.id === nodeId);

  const cellsHistory = useSelector((state: RootState) => state.scriptCells);
  const files = useSelector((state: RootState) => state.files);

  const cells: { [nodeId: string]: ScriptCell[] } = cellsHistory.present;
  const currentNodeCells = React.useMemo(() => cells[nodeId] || [], [cells, nodeId]);

  const [selectedCellId, setSelectedCellId] = React.useState<string | null>(null);
  const [savedStateIndex, setSavedStateIndex] = React.useState<number>(cellsHistory.index || 0);
  const [hasUnsavedChanges, setHasUnsavedChanges] = React.useState(false);
  const [isUnsavedChangesModalOpen, setIsUnsavedChangesModalOpen] = React.useState(false);
  const [isRemoveConfirmationOpen, setIsRemoveConfirmationOpen] = React.useState(false);
  const [isFilesPanelOpen, setIsFilesPanelOpen] = React.useState(false);

  const [shouldSaveAfterRerender, setShouldSaveAfterRerender] = React.useState(false);
  const [storedCommitCount, setStoredCommitCount] = React.useState(0);
  const addCellTimer = React.useRef<NodeJS.Timeout | null>(null);

  React.useEffect(
    function selectFirstCellIfNoneSelected() {
      const isValidCellSelected = selectedCellId && currentNodeCells.find((cell) => cell.id === selectedCellId);
      if (currentNodeCells.length && !isValidCellSelected) {
        setSelectedCellId(currentNodeCells[0].id);
      }
    },
    [currentNodeCells, selectedCellId],
  );

  React.useEffect(
    function addCellIfNoneExist() {
      if (!currentNodeCells.length) {
        handleAddCellBelow();
        setShouldSaveAfterRerender(true);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentNodeCells],
  );

  React.useEffect(() => {
    setHasUnsavedChanges(savedStateIndex !== cellsHistory.index);
  }, [cellsHistory, savedStateIndex]);

  // TODO: remove after flippers.flowDiagram = true
  React.useEffect(() => {
    if (commitCount !== storedCommitCount) {
      setStoredCommitCount(commitCount);
      setSavedStateIndex(cellsHistory.index || 0);
    }
  }, [storedCommitCount, commitCount, cellsHistory]);

  const getCurrentCellIndex = React.useCallback((): number => {
    return currentNodeCells.findIndex((cell) => cell.id === selectedCellId);
  }, [currentNodeCells, selectedCellId]);

  const getNextSelectedCell = React.useCallback(
    (cellIdToRemove: string) => {
      const cellIndexToRemove = currentNodeCells.findIndex((cell) => cell.id === cellIdToRemove);
      const isRemovingLastCell = cellIndexToRemove === currentNodeCells.length - 1;
      if (!isRemovingLastCell) {
        return currentNodeCells[cellIndexToRemove + 1];
      }
      return cellIndexToRemove === 0 ? null : currentNodeCells[cellIndexToRemove - 1];
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentNodeCells.length],
  );

  const handleRevert = React.useCallback(() => {
    dispatch({ type: 'scriptCellsJumpToPast', index: savedStateIndex });
  }, [dispatch, savedStateIndex]);

  const handleAddCell = React.useCallback(
    (newCellIndex) => {
      const cellId = uuidv4();
      const newCell: ScriptCell = {
        id: cellId,
        input: '',
      };

      dispatch(addScriptCell({ nodeId, newCell, newCellIndex }));
      setSelectedCellId(cellId);

      // Brief timeout allows overflow menu to close and return focus to trigger button
      if (addCellTimer.current) clearTimeout(addCellTimer.current);
      addCellTimer.current = setTimeout(() => {
        const $cell = document.getElementsByClassName(cellClassName)[newCellIndex] as HTMLElement;
        const $newCellEditor = $cell?.getElementsByClassName(cellEditorClassName)[0] as HTMLElement;
        $newCellEditor?.focus();
        scrollTo($cell?.offsetTop);
      }, 200);
    },
    [dispatch, nodeId],
  );

  const handleAddCellAbove = React.useCallback(() => {
    const newCellIndex = getCurrentCellIndex();
    handleAddCell(newCellIndex);
  }, [getCurrentCellIndex, handleAddCell]);

  const handleAddCellBelow = React.useCallback(() => {
    const newCellIndex = getCurrentCellIndex() + 1;
    handleAddCell(newCellIndex);
  }, [getCurrentCellIndex, handleAddCell]);

  const handleSelectCell = React.useCallback(
    (cellId: string) => () => {
      setSelectedCellId(cellId);
    },
    [],
  );

  const handleRemoveCell = React.useCallback(
    (cellId: string | null) => () => {
      if (cellId) {
        const nextSelectedCell = getNextSelectedCell(cellId);
        dispatch(removeScriptCell({ nodeId, cellId }));
        setSelectedCellId(nextSelectedCell && nextSelectedCell.id);
      }
    },
    [dispatch, getNextSelectedCell, nodeId],
  );

  const handleCellInputChange = React.useCallback(
    (value: string, prevCell: ScriptCell) => {
      if (prevCell && prevCell.input !== value) {
        const newCell = { ...prevCell, input: value };
        dispatch(updateScriptCell({ nodeId, cell: newCell }));
      }
    },
    [dispatch, nodeId],
  );

  const handleRunCell = React.useCallback(
    (cellId: string) => () => {
      dispatch(startScriptCellExecution({ nodeId, cellId }));
      webSocket.sendRunScriptCellMessage(nodeId, cellId);
    },
    [dispatch, nodeId, webSocket],
  );

  const handleRunThisAndAbove = React.useCallback(
    (cellId: string) => () => {
      const lastIndex = currentNodeCells.findIndex((cell) => cell.id === cellId) + 1;
      const cellIds = currentNodeCells.slice(0, lastIndex).map((cell) => cell.id);

      dispatch(startScriptCellExecution({ nodeId, cellId: cellIds[0] }));
      webSocket.sendRunSomeCellsMessage(nodeId, cellIds);
    },
    [currentNodeCells, dispatch, nodeId, webSocket],
  );

  const handleRunThisAndBelow = React.useCallback(
    (cellId: string) => () => {
      const startIndex = currentNodeCells.findIndex((cell) => cell.id === cellId);
      const cellIds = currentNodeCells.slice(startIndex).map((cell) => cell.id);

      dispatch(startScriptCellExecution({ nodeId, cellId: currentNodeCells[startIndex].id }));
      webSocket.sendRunSomeCellsMessage(nodeId, cellIds);
    },
    [currentNodeCells, dispatch, nodeId, webSocket],
  );

  const handleStop = React.useCallback(() => {
    webSocket.sendStopExecutionMessage();
  }, [webSocket]);

  const handleRemoveCurrentCell = () => {
    handleRemoveCell(selectedCellId)();
  };

  const handleRunCurrentCell = React.useCallback(() => {
    const currentCell = currentNodeCells.find((cell) => cell.id === selectedCellId);
    if (currentCell) handleRunCell(currentCell.id)();
  }, [currentNodeCells, handleRunCell, selectedCellId]);

  const handleRunNode = () => {
    dispatch(startScriptCellExecution({ nodeId, cellId: currentNodeCells[0].id }));
    webSocket.sendRunNodeMessage(nodeId);
  };

  const handleSave = () => {
    setSavedStateIndex(cellsHistory.index || 0);
  };

  const handleClose = () => {
    if (hasUnsavedChanges) {
      setIsUnsavedChangesModalOpen(true);
    } else {
      onClose();
    }
  };

  const handleDiscardAndClose = () => {
    // TODO: when we implement user-controlled undo, discarding changes may make the history jump to the future instead.
    dispatch({ type: 'scriptCellsJumpToPast', index: savedStateIndex });
    // TODO: stop executing all cells
    onClose();
  };

  const handleSaveAndClose = () => {
    handleSave();
    onClose();
  };

  const toggleFilePanel = () => {
    setIsFilesPanelOpen((state) => !state);
  };

  const handleFileDownload = (fileName: string) => {
    webSocket.sendFileDownloadMessage(fileName);
  };

  if (shouldSaveAfterRerender) {
    handleSave();
    setShouldSaveAfterRerender(false);
  }

  const handleShowRemoveConfirmation = React.useCallback((): void => {
    setIsRemoveConfirmationOpen(true);
  }, []);

  useShortcutKeys({
    currentNodeCells,
    getCurrentCellIndex,
    isConnected: webSocket.isConnected,
    onRunCurrentCell: handleRunCurrentCell,
    onStop: handleStop,
    onAddCellAbove: handleAddCellAbove,
    onAddCellBelow: handleAddCellBelow,
    onShowRemoveConfirmation: handleShowRemoveConfirmation,
    onSelectCell: handleSelectCell,
  });

  const nodeDetailsChildren = (
    <>
      <div className="node-details-header">
        <div className="node-details-header__icon-and-name">
          <div className="node-details-header__node-icon">
            <ScriptIcon size={stylers.spacer(3)} color={tokens.color.gold}></ScriptIcon>
          </div>
          <Heading level={2} displayLevel={4} className="node-details-header__node-name">
            {currentNode!.name}
          </Heading>
        </div>
        <ScriptNodeToolbar
          hasUnsavedChanges={hasUnsavedChanges}
          onAddCell={handleAddCellBelow}
          onRemoveCell={handleRemoveCurrentCell}
          onRevert={handleRevert}
          onRunCell={handleRunCurrentCell}
          onRunNode={handleRunNode}
          onSave={handleSave}
          onStop={handleStop}
        />
        {flippers.flowDiagram && (
          <div className="node-details-header__close-btn-wrapper">
            <Button.Close className="node-details-header__close-btn" onClick={handleClose} />
          </div>
        )}
        <div className="node-details-header__buttons">
          <Button
            className="node-details-header__button"
            data-pendo-anchor="canvas-header__variables"
            icon={<VariablesIcon size={iconSize} />}
            onClick={onVariablesButtonClick}
          >
            {I18n.t('canvas.header.variables_button')}
          </Button>
          {flippers.containerFiles && (
            <Button
              className="node-details-header__button node-details-header__button--files"
              data-pendo-anchor="canvas-header__files"
              data-testid="node-details-header__button--files"
              icon={<FilesIcon size={iconSize} />}
              onClick={toggleFilePanel}
            >
              {files.length}
            </Button>
          )}
          <Button
            className="node-details-header__button"
            data-pendo-anchor="canvas-header__commit"
            data-testid="node-details-header__button--commit"
            kind="primary"
            onClick={onCommitButtonClick}
          >
            {I18n.t('canvas.header.commit_button')}
          </Button>
        </div>
      </div>
      <div className={cellContainerClassName}>
        <ScriptToast isComplete={webSocket.isConnected} />
        <div className="node-details__content-container">
          <div className="node-details__content">
            <div role="list">
              {currentNodeCells.map((cell, index) => (
                <ScriptNodeCell
                  cell={cell}
                  isFirst={index === 0}
                  isLast={index === currentNodeCells.length - 1}
                  isSelected={cell.id === selectedCellId}
                  key={cell.id}
                  onAddCellAbove={handleAddCellAbove}
                  onAddCellBelow={handleAddCellBelow}
                  onClickRunThisAndAbove={handleRunThisAndAbove}
                  onClickRunThisAndBelow={handleRunThisAndBelow}
                  onDeleteClick={handleRemoveCell}
                  onInputChange={handleCellInputChange}
                  onRun={handleRunCell}
                  onSelect={handleSelectCell}
                  onStop={handleStop}
                />
              ))}
            </div>
            {isRemoveConfirmationOpen && (
              <RemoveCellConfirmation
                cellIndex={getCurrentCellIndex()}
                onClose={() => {
                  setIsRemoveConfirmationOpen(false);
                }}
                onConfirm={() => {
                  handleRemoveCurrentCell();
                  setIsRemoveConfirmationOpen(false);
                }}
              />
            )}
          </div>
        </div>
        {flippers.containerFiles && (
          <FilesPanel
            files={files}
            isDownloadDisabled={webSocket.isExecuting || webSocket.isFileDownloading}
            isOpen={isFilesPanelOpen}
            onClose={toggleFilePanel}
            onDownload={handleFileDownload}
          ></FilesPanel>
        )}
      </div>
    </>
  );

  return (
    <>
      <div className="node-details node-details--script-node">{nodeDetailsChildren}</div>
      {isUnsavedChangesModalOpen && (
        <UnsavedChangesModal
          headingText={I18n.t('script_node.unsaved_changes_modal.heading')}
          bodyText={I18n.t('script_node.unsaved_changes_modal.body')}
          confirmButtonText={I18n.t('script_node.unsaved_changes_modal.confirm_button')}
          data-pendo-anchor="script-node__unsaved-changes"
          declineButtonText={I18n.t('script_node.unsaved_changes_modal.decline_button')}
          onCancel={() => setIsUnsavedChangesModalOpen(false)}
          onConfirm={handleSaveAndClose}
          onDecline={handleDiscardAndClose}
        />
      )}
    </>
  );
}
