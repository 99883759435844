import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import DocumentTitle from 'react-document-title';
import PageHeader from '@acl-services/sriracha-page-header';
import Breadcrumbs from '@paprika/breadcrumbs';
import { useI18n } from '@paprika/l10n';
import AppVersion from './AppVersion';
import { RootState } from 'rootReducer';
import './CanvasHeader.scss';

type Props = {
  onClickBreadcrumbsLink: (string) => void;
};

export default function CanvasHeader({ onClickBreadcrumbsLink }: Props) {
  const I18n = useI18n();
  const { robotVersion } = useSelector((state: RootState) => state.robotVersion);
  const { locale, robotUrl } = useSelector((state: RootState) => state.highbondInfo);
  const { robot } = useSelector((state: RootState) => state.robot);
  const { robotId } = useParams();
  const robotDetailsUrl = `${robotUrl}/development/${robotId}/scripts`;

  function handleClickRobotPage(e) {
    if (e.metaKey || e.ctrlKey) {
      return;
    }

    e.preventDefault();
    onClickBreadcrumbsLink(robotUrl);
    return false;
  }

  function handleClickRobotDetailsPage(e) {
    if (e.metaKey || e.ctrlKey) {
      return;
    }

    e.preventDefault();
    onClickBreadcrumbsLink(robotDetailsUrl);
    return false;
  }

  function getTitle() {
    if (robotVersion.version === 0 || robotVersion.comment === null) return I18n.t('headings.new_script');

    return robotVersion.comment;
  }

  return (
    <DocumentTitle title={`${getTitle()} - ${I18n.t('product_name.robots')} - ${I18n.t('product_name.highbond')}`}>
      <PageHeader
        className="canvas-header"
        data-pendo-anchor="page-header"
        data-testid="canvas-header"
        isDark
        locale={locale}
      >
        <PageHeader.Icon data-testid="canvas-header-app-version">
          <AppVersion appVersionText={I18n.t('canvas.header.app_version', { appVersion: robotVersion.version })} />
        </PageHeader.Icon>
        <PageHeader.Breadcrumbs>
          <Breadcrumbs>
            <Breadcrumbs.Link href={robotUrl} onClick={handleClickRobotPage} data-testid="canvas-header-back">
              {I18n.t('product_name.robots')}
            </Breadcrumbs.Link>
            <Breadcrumbs.Link
              href={robotDetailsUrl}
              onClick={handleClickRobotDetailsPage}
              data-testid="canvas-header-robot-name"
            >
              {robot.name}
            </Breadcrumbs.Link>
          </Breadcrumbs>
        </PageHeader.Breadcrumbs>
        <PageHeader.Title>{getTitle()}</PageHeader.Title>
      </PageHeader>
    </DocumentTitle>
  );
}
