import React from 'react';

import Button from '@paprika/button';
import Spinner from '@paprika/spinner';
import Toast from '@paprika/toast';
import Confirmation from '@paprika/confirmation';
import { useI18n } from '@paprika/l10n';

import ScriptCell from 'types/ScriptCell';
import { ScriptCellOutput } from 'types/ScriptCell';
import { ExecutionOutput } from 'enums/ExecutionResult';
import { useWebSocket } from 'providers/WebSocketProvider';

import ScriptCellDropdown from './ScriptCellDropdown';
import ScriptNodeIcon from './ScriptNodeIcon';
import CodeEditor from './CodeEditor';
import ScriptTooltipContent from './ScriptTooltipContent';
import { shortcutKeys } from './useShortcutKeys';
import { cellClassName } from './ScriptNodeHelpers';

import './ScriptNodeCell.scss';

type Props = {
  cell: ScriptCell;
  isFirst: boolean;
  isLast: boolean;
  isSelected: boolean;
  onAddCellAbove: () => void;
  onAddCellBelow: () => void;
  onClickRunThisAndAbove: (cellId: string) => () => void;
  onClickRunThisAndBelow: (cellId: string) => () => void;
  onDeleteClick: (cellId: string) => () => void;
  onInputChange: (value: string, prevCell: ScriptCell) => void;
  onRun: (cellId: string) => () => void;
  onSelect: (cellId: string) => () => void;
  onStop: () => void;
};

function ScriptNodeCell({
  cell,
  isFirst,
  isLast,
  isSelected,
  onAddCellAbove,
  onAddCellBelow,
  onClickRunThisAndAbove,
  onClickRunThisAndBelow,
  onDeleteClick,
  onInputChange,
  onRun,
  onSelect,
  onStop,
}: Props) {
  const { executionCount, input, isPendingOutput, outputs } = cell;
  const I18n = useI18n();
  const webSocket = useWebSocket();

  const renderOutput = (output: ScriptCellOutput, index: number) => {
    if (output.category === ExecutionOutput.Error) {
      return (
        <div key={index} className="script-node-cell__output script-node-cell__output--error" role="listitem">
          <Toast hasCloseButton={false} kind="error" className="script-node-cell__output-content" tabIndex={0}>
            {(output.content as string[]).map((line) => line + '\n')}
          </Toast>
        </div>
      );
    } else if (output.category === ExecutionOutput.Html) {
      return (
        <div
          key={index}
          data-testid="script-cell-table"
          className="script-node-cell__output script-node-cell__output--table"
          role="listitem"
        >
          <div
            className="script-node-cell__output-content"
            dangerouslySetInnerHTML={{ __html: output.content as string }}
            tabIndex={0}
          />
        </div>
      );
    } else {
      return (
        <div key={index} className="script-node-cell__output script-node-cell__output--text" role="listitem">
          <pre className="script-node-cell__output-content" tabIndex={0}>
            {output.content}
          </pre>
        </div>
      );
    }
  };

  const isRunButtonDisabled =
    webSocket.isExecuting ||
    webSocket.isFileDownloading ||
    !webSocket.isConnected ||
    webSocket.isPermanentlyDisconnected;
  const isLoadingEnvironment = !webSocket.isConnected;

  const runButton = (
    <Button.Icon
      a11yText={I18n.t('script_node.cell.run_tip')}
      className="script-node-cell__execution-button"
      data-pendo-anchor="cell-actions__run"
      isDisabled={isRunButtonDisabled}
      kind="minor"
      onClick={onRun(cell.id)}
      onFocus={onSelect(cell.id)}
    >
      <ScriptNodeIcon
        iconType="runCell"
        isCentered={false}
        isDisabled={isRunButtonDisabled}
        tooltipContent={
          <ScriptTooltipContent
            content={
              isLoadingEnvironment
                ? I18n.t('script_node.toolbar.run_tip_loading')
                : I18n.t('script_node.toolbar.run_tip')
            }
            shortcutCombo={shortcutKeys.RUN}
          />
        }
      />
    </Button.Icon>
  );

  const stopButton = (
    <Confirmation
      align="bottom"
      confirmLabel={I18n.t('script_node.cell.stop_confirmation_label')}
      edge="left"
      heading={I18n.t('script_node.cell.stop_confirmation_heading')}
      onConfirm={handleConfirmStop}
      zIndex={9}
    >
      <Confirmation.TriggerButton
        a11yText={I18n.t('script_node.cell.stop_tip')}
        buttonType="icon"
        className="script-node-cell__execution-button"
        data-pendo-anchor="cell-actions__stop"
        kind="minor"
        onFocus={onSelect(cell.id)}
      >
        <ScriptNodeIcon
          iconType="stopExecute"
          isCentered={false}
          tooltipContent={
            <ScriptTooltipContent
              content={
                isLoadingEnvironment ? I18n.t('script_node.cell.stop_tip_loading') : I18n.t('script_node.cell.stop_tip')
              }
              shortcutCombo={shortcutKeys.STOP}
            />
          }
        />
      </Confirmation.TriggerButton>
    </Confirmation>
  );

  function handleConfirmStop(handleCloseConfirm) {
    onStop();
    handleCloseConfirm();
  }

  return (
    <div
      className={`${cellClassName} ${isSelected ? 'script-node-cell--selected' : ''}`}
      data-testid="script-cell"
      onClick={onSelect(cell.id)}
      role="listitem"
      aria-label={I18n.t(`script_node.cell.${isSelected ? 'selected' : 'unselected'}`)}
      tabIndex={-1}
    >
      <div className="script-node-cell__input">
        {isPendingOutput && !webSocket.isPermanentlyDisconnected ? stopButton : runButton}
        <ScriptCellDropdown
          onAddCellAbove={onAddCellAbove}
          onAddCellBelow={onAddCellBelow}
          onClickRunThisAndAbove={onClickRunThisAndAbove(cell.id)}
          onClickRunThisAndBelow={onClickRunThisAndBelow(cell.id)}
          onDeleteCell={onDeleteClick(cell.id)}
          isFirst={isFirst}
          isLast={isLast}
          isDisabled={webSocket.isExecuting}
        />
        <div className="script-node-cell__execution-count">
          {`[${isPendingOutput ? '*' : executionCount || '\u00A0'}]`}
        </div>
        <CodeEditor
          cell={cell}
          isReadOnly={webSocket.isExecuting}
          onChange={onInputChange}
          onSelect={onSelect(cell.id)}
          value={input}
        />
      </div>
      <div role="list">{outputs && outputs.map((output, index) => renderOutput(output, index))}</div>
      {isPendingOutput && !webSocket.isPermanentlyDisconnected && (
        <Spinner a11yText={I18n.t('script_node.cell.spinner_alert')} />
      )}
    </div>
  );
}

export default React.memo(ScriptNodeCell);
