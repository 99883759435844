import merge from 'lodash.merge';
import Variable from 'types/Variable';
import VariableErrors from 'types/VariableErrors';
import VariableProperty from 'enums/VariableProperty';
import VariableType from 'enums/VariableType';

export default class VariablesValidator {
  t: (string) => string;

  constructor(t) {
    this.t = t;
  }

  isVariableEmpty(variable: Variable): boolean {
    const { isReadOnly, isTaskInput, isTaskInputValueOptional, name, taskInputDescription, type, value } = variable;
    return (
      !isReadOnly &&
      !isTaskInput &&
      !isTaskInputValueOptional &&
      !name &&
      !taskInputDescription &&
      !value &&
      type === VariableType.Character
    );
  }

  getSingleErrors(variables: Variable[]): VariableErrors {
    let errors: VariableErrors = {};
    for (const v of variables) {
      errors[v.id] = {};
      [VariableProperty.Name, VariableProperty.TaskInputLabel, VariableProperty.Value].forEach(
        (property: VariableProperty) => {
          errors[v.id][property] = this.getSingleErrorForProperty(v, property);
        },
      );
    }
    return errors;
  }

  // TODO: add case for duplicate visible task input labels
  getSingleErrorForProperty(variable: Variable, property: VariableProperty): string | null {
    switch (property) {
      case VariableProperty.Name:
        if (!variable.name) {
          return this.t('variables.name_required_error');
        }
        break;
      case VariableProperty.TaskInputLabel:
        if (variable.isTaskInput && !variable.taskInputLabel) {
          return this.t('variables.task_input_label_required_error');
        }
        break;
      case VariableProperty.Value:
        if (variable.type === VariableType.SystemUser && !variable.value) {
          return this.t('variables.system_user_required_error');
        }
        break;
    }
    return null;
  }

  getDuplicateErrors(variables: Variable[]): VariableErrors {
    let errors: VariableErrors = {};
    for (const v of variables) {
      const nameDuplicates = this.getDuplicateErrorsForProperty(
        v,
        VariableProperty.Name,
        this.t('variables.duplicate_name_error'),
        variables,
      );
      // only check visible task input labels
      const taskInputDuplicates = v.isTaskInput
        ? this.getDuplicateErrorsForProperty(
            v,
            VariableProperty.TaskInputLabel,
            this.t('variables.duplicate_task_input_label_error'),
            variables.filter((_v) => _v.isTaskInput),
          )
        : {};
      errors = merge(errors, nameDuplicates, taskInputDuplicates);
    }
    return errors;
  }

  private getDuplicateErrorsForProperty(
    variable: Variable,
    property: VariableProperty,
    errorText: string,
    variables: Variable[],
  ) {
    const duplicates = this.getDuplicates(variable, property, variables);
    return this.reduceVariablesToObject(duplicates, property, errorText);
  }

  private getDuplicates(variable: Variable, property: VariableProperty, variables: Variable[]) {
    return variables.filter((v) => v.id !== variable.id && v[property] && v[property] === variable[property]);
  }

  private reduceVariablesToObject(duplicates: Variable[], property: VariableProperty, text: string) {
    return duplicates.reduce((obj, v) => {
      return { ...obj, [v.id]: { [property]: text } };
    }, {});
  }

  getTotalErrorCount(...args: VariableErrors[]) {
    const allErrors = merge({}, ...args);
    let count = 0;
    for (const errorObj of Object.values(allErrors)) {
      for (const error of Object.values(errorObj as object)) {
        if (error) count++;
      }
    }
    return count;
  }
}
