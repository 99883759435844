import store from 'store';
import SpecialNodeKind from 'enums/SpecialNodeKind';
import FlowVersion from 'enums/FlowVersion';
import VariableType from 'enums/VariableType';
import FlowNode from 'types/FlowNode';
import SecretEncrypt from './SecretEncrypt';

function getRunNodeMessageBody(version: FlowVersion, nodeId: string) {
  return {
    nodeId,
    flow: {
      version,
      nodes: getNodesWithScriptCells(),
      edges: store.getState().edges.present,
      variables: getVariablesForExecution(),
      environment: getEnvironment(),
    },
  };
}

function getRunFlowMessageBody(version: FlowVersion) {
  return {
    flow: {
      version,
      nodes: getNodesWithScriptCells(),
      edges: store.getState().edges.present,
      variables: getVariablesForExecution(),
      environment: getEnvironment(),
    },
  };
}

function getRunSomeCellsMessageBody(version: FlowVersion, nodeId: string, selectedCellIds: string[]) {
  const nodes = getNodesWithScriptCells();
  nodes[0].config.cells = nodes[0].config.cells.filter((cell) =>
    selectedCellIds.some((selectedCellId) => selectedCellId === cell.id),
  );

  return {
    nodeId,
    flow: {
      version,
      nodes,
      edges: store.getState().edges.present,
      variables: getVariablesForExecution(),
      environment: getEnvironment(),
    },
  };
}

function getRunScriptCellMessageBody(version: FlowVersion, nodeId: string, cellId: string) {
  return {
    nodeId,
    cellId,
    flow: {
      version,
      nodes: getNodesWithScriptCells(),
      edges: store.getState().edges.present,
      variables: getVariablesForExecution(),
      environment: getEnvironment(),
    },
  };
}

function getCreateRobotVersionRequestBody(version: FlowVersion, flowName, comment = '', saveOutput = false) {
  return {
    name: flowName,
    comment,
    raw: {
      version,
      nodes: getNodesWithScriptCells(),
      edges: store.getState().edges.present,
      variables: getVariablesForCommit(),
      environment: getEnvironment(),
      log_stdout: saveOutput, // eslint-disable-line @typescript-eslint/camelcase
    },
  };
}

function getNodesWithScriptCells() {
  const { flowNodes, scriptCells } = store.getState();
  return flowNodes.present.map((node) => {
    if (isScriptNode(node) && scriptCells.present[node.id]) {
      const cells = scriptCells.present[node.id];
      const cellsWithoutOutput = cells.map((c) => ({ id: c.id, input: c.input }));
      // need to make copy of immutable node object to add nodeConfig
      return { ...node, config: { cells: cellsWithoutOutput } };
    } else {
      return node;
    }
  });
}

function isScriptNode(node: FlowNode) {
  return node.kind === SpecialNodeKind.Script;
}

function getVariablesForCommit() {
  const variables = store.getState().variables.present;
  return variables.map((variable) => ({
    ...variable,
    taskInputLabel: variable.isTaskInput ? variable.taskInputLabel : undefined,
    value: variable.type === VariableType.Password ? '' : variable.value,
  }));
}

function getVariablesForExecution() {
  const secretEncrypt = new SecretEncrypt();
  secretEncrypt.setPublicKey(store.getState().highbondInfo.encryptionKey);

  const variables = store.getState().variables.present;
  return variables.map((variable) => ({
    ...variable,
    value: variable.type === VariableType.Password ? secretEncrypt.encrypt(variable.value) : variable.value,
  }));
}

function getEnvironment() {
  return store.getState().environment;
}

export default {
  getEnvironment,
  getRunNodeMessageBody,
  getRunFlowMessageBody,
  getRunSomeCellsMessageBody,
  getRunScriptCellMessageBody,
  getCreateRobotVersionRequestBody,
};
