import React from 'react';

import Confirmation from '@paprika/confirmation';
import { useI18n } from '@paprika/l10n';
import Button from '@paprika/button';
import Heading from '@paprika/heading';

import ScriptNodeIcon from './ScriptNodeIcon';
import flippers from 'utils/flippers';
import { useWebSocket } from 'providers/WebSocketProvider';
import { shortcutKeys } from './useShortcutKeys';
import ScriptTooltipContent from './ScriptTooltipContent';

import './ScriptNodeToolbar.scss';

type ScriptNodeToolbarProps = {
  children?: React.ReactNode;
  hasUnsavedChanges: boolean;
  onAddCell: () => void;
  onRemoveCell: () => void;
  onRevert: () => void;
  onRunCell: () => void;
  onRunNode: () => void;
  onSave: () => void;
  onStop: () => void;
};

function ScriptNodeToolbar({
  hasUnsavedChanges,
  onAddCell,
  onRemoveCell,
  onRevert,
  onRunCell,
  onRunNode,
  onSave,
  onStop,
}: ScriptNodeToolbarProps) {
  const I18n = useI18n();
  const webSocket = useWebSocket();

  const areRunButtonsDisabled =
    webSocket.isExecuting ||
    webSocket.isFileDownloading ||
    !webSocket.isConnected ||
    webSocket.isPermanentlyDisconnected;
  const isStopButtonDisabled = !webSocket.isExecuting || webSocket.isPermanentlyDisconnected;
  const isRevertButtonDisabled = webSocket.isExecuting || !hasUnsavedChanges;
  const isLoadingEnvironment = !webSocket.isConnected;

  const handleConfirmRemoveCell = (handleCloseConfirm) => {
    onRemoveCell();
    handleCloseConfirm();
  };

  const handleConfirmStop = (handleCloseConfirm) => {
    onStop();
    handleCloseConfirm();
  };

  const handleConfirmRevert = (handleCloseConfirm) => {
    onRevert();
    handleCloseConfirm();
  };

  const saveButton = (
    <>
      <Button.Icon
        onClick={onSave}
        className={`script-node-toolbar__button ${hasUnsavedChanges ? 'script-node-toolbar__button--warn' : ''}`}
        kind="minor"
        a11yText={I18n.t(`script_node.toolbar.save_tip${hasUnsavedChanges ? '_with_warning' : ''}`)}
      >
        <ScriptNodeIcon iconType="save" tooltipContent={I18n.t('script_node.toolbar.save_tip')} />
      </Button.Icon>
      <div className="script-node-toolbar__divider" />
    </>
  );

  const revertButton = (
    <Confirmation
      align="bottom"
      confirmLabel={I18n.t('script_node.toolbar.revert_confirmation_label')}
      edge="left"
      heading={I18n.t('script_node.toolbar.revert_confirmation_heading')}
      onConfirm={handleConfirmRevert}
      zIndex={9}
    >
      <Confirmation.TriggerButton
        a11yText={I18n.t('script_node.toolbar.revert_tip')}
        buttonType="icon"
        className="script-node-toolbar__button"
        data-pendo-anchor="script-toolbar__revert"
        isDisabled={isRevertButtonDisabled}
        kind="minor"
      >
        <ScriptNodeIcon
          iconType="revertChange"
          isDisabled={isRevertButtonDisabled}
          tooltipContent={I18n.t('script_node.toolbar.revert_tip')}
        />
      </Confirmation.TriggerButton>
    </Confirmation>
  );

  const outputDropdown = (
    <>
      <div className="script-node-toolbar__divider" />
      <Heading className="script-node-toolbar__output-heading" level={6}>
        {I18n.t('script_node.toolbar.output_label')}
      </Heading>
      <div className="script-node-toolbar__output-listbox" />
    </>
  );

  return (
    <div className="script-node-toolbar">
      {flippers.flowDiagram && saveButton}
      <Button.Icon
        a11yText={I18n.t('script_node.toolbar.add_tip')}
        className="script-node-toolbar__button"
        data-pendo-anchor="script-toolbar__add-cell"
        isDisabled={webSocket.isExecuting}
        kind="minor"
        onClick={onAddCell}
      >
        <ScriptNodeIcon
          iconType="addCell"
          isDisabled={webSocket.isExecuting}
          tooltipContent={
            <ScriptTooltipContent
              content={I18n.t('script_node.toolbar.add_tip')}
              shortcutCombo={shortcutKeys.ADD_BELOW}
            />
          }
        />
      </Button.Icon>
      <div className="script-node-toolbar__divider" />
      <Button.Icon
        a11yText={I18n.t('script_node.toolbar.run_tip')}
        className="script-node-toolbar__button"
        data-pendo-anchor="script-toolbar__run-cell"
        isDisabled={areRunButtonsDisabled}
        kind="minor"
        onClick={onRunCell}
      >
        <ScriptNodeIcon
          iconType="runCell"
          isDisabled={areRunButtonsDisabled}
          tooltipContent={
            <ScriptTooltipContent
              content={
                isLoadingEnvironment
                  ? I18n.t('script_node.toolbar.run_tip_loading')
                  : I18n.t('script_node.toolbar.run_tip')
              }
              shortcutCombo={shortcutKeys.RUN}
            />
          }
        />
      </Button.Icon>
      <Button.Icon
        a11yText={I18n.t('script_node.toolbar.run_all_tip')}
        className="script-node-toolbar__button"
        data-pendo-anchor="script-toolbar__run-all"
        isDisabled={areRunButtonsDisabled}
        kind="minor"
        onClick={onRunNode}
      >
        <ScriptNodeIcon
          iconType="runNode"
          isDisabled={areRunButtonsDisabled}
          tooltipContent={
            <ScriptTooltipContent
              content={
                isLoadingEnvironment
                  ? I18n.t('script_node.toolbar.run_all_tip_loading')
                  : I18n.t('script_node.toolbar.run_all_tip')
              }
            />
          }
        />
      </Button.Icon>
      <Confirmation
        align="bottom"
        confirmLabel={I18n.t('script_node.toolbar.stop_confirmation_label')}
        edge="left"
        heading={I18n.t('script_node.toolbar.stop_confirmation_heading')}
        onConfirm={handleConfirmStop}
        zIndex={9}
      >
        <Confirmation.TriggerButton
          a11yText={I18n.t('script_node.toolbar.stop_tip')}
          buttonType="icon"
          className="script-node-toolbar__button"
          data-pendo-anchor="script-toolbar__stop"
          kind="minor"
          isDisabled={isStopButtonDisabled}
        >
          <ScriptNodeIcon
            iconType="stopExecute"
            isDisabled={isStopButtonDisabled}
            tooltipContent={
              <ScriptTooltipContent
                content={
                  isLoadingEnvironment
                    ? I18n.t('script_node.toolbar.stop_tip_loading')
                    : I18n.t('script_node.toolbar.stop_tip')
                }
                shortcutCombo={shortcutKeys.STOP}
              />
            }
          />
        </Confirmation.TriggerButton>
      </Confirmation>
      <div className="script-node-toolbar__divider" />
      <Confirmation
        align="bottom"
        confirmLabel={I18n.t('script_node.toolbar.delete_confirmation_label')}
        edge="left"
        heading={I18n.t('script_node.toolbar.delete_confirmation_heading')}
        onConfirm={handleConfirmRemoveCell}
        zIndex={9}
      >
        <Confirmation.TriggerButton
          a11yText={I18n.t('script_node.toolbar.delete_tip')}
          buttonType="icon"
          className="script-node-toolbar__button"
          data-pendo-anchor="script-toolbar__remove-cell"
          isDisabled={webSocket.isExecuting}
          kind="minor"
        >
          <ScriptNodeIcon
            iconType="removeCell"
            isDisabled={webSocket.isExecuting}
            tooltipContent={
              <ScriptTooltipContent
                content={I18n.t('script_node.toolbar.delete_tip')}
                shortcutCombo={shortcutKeys.DELETE}
              />
            }
          />
        </Confirmation.TriggerButton>
      </Confirmation>
      <div className="script-node-toolbar__divider" />
      {!flippers.flowDiagram && revertButton}
      {flippers.flowDiagram && outputDropdown}
    </div>
  );
}

export default ScriptNodeToolbar;
